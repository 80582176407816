<template>
  <div class="user">
    <div class="tit">{{ $t('reg_bankInfo_1') }}</div>
    <div class="user-box" v-if="type != 3">
      <div class="addr">
        {{ $t('reg_bankInfo_2') }}：
        <el-popover
          placement="top"
          :title="$t('reg_bankInfo_3')"
          width="300"
          trigger="hover"
          :content="$t('reg_bankInfo_4')"
        >
          <i slot="reference" class="el-icon-question"></i>
        </el-popover>
      </div>
      <div class="upload">
        <el-upload
          class="upload-demo"
          ref="uploadimg"
          action="#"
          :http-request="handleRequest"
          :before-remove="beforeRemove"
          multiple
          :limit="10"
          :file-list="fileList"
        >
          <el-button size="small" icon="el-icon-upload2" type="primary">{{
            $t('reg_bankInfo_5')
          }}</el-button>
          <div slot="tip" class="el-upload__tip">
            {{ $t('reg_bankInfo_6') }}：PNG, JPEG, PDF
          </div>
        </el-upload>
      </div>
    </div>
    <div class="textCenter">
      <el-button
        v-if="type != 3"
        :loading="nextLoading"
        @click="next"
        type="primary"
      >
        {{ $t('reg_bankInfo_7') }}
      </el-button>
      <el-button
        v-else
        :loading="nextLoading"
        @click="nextOverseas"
        type="primary"
      >
        {{ $t('reg_bankInfo_8') }}
      </el-button>
      <el-button @click="back" type="text">{{
        $t('reg_bankInfo_9')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: localStorage.getItem('sm_email'),
      },
      type: '',
      fileList: [],
      nextLoading: false,
    }
  },
  created() {
    this.type = localStorage.getItem('sm_type')
    this.fileList = []
    if (localStorage.getItem('sm_userinfo')) {
      this.ruleForm = {
        ...JSON.parse(localStorage.getItem('sm_userinfo')),
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
      }
      if (this.ruleForm.addrimage) {
        this.fileList = JSON.parse(this.ruleForm.addrimage)
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'MainlandUser' })
    },
    async next() {
      if (this.fileList.length <= 0) {
        this.$message({
          message: '请上传地址证明',
          type: 'warning',
        })
        return
      }
      this.ruleForm.addrimage = this.fileList
      this.nextLoading = true
      let res = await this.$axios.post('/api/User/register', this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.$router.push({ name: 'Limit' })
        localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
      }
    },
    async nextOverseas() {
      this.nextLoading = true
      let res = await this.$axios.post('/api/User/register', this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.$router.push({ name: 'Limit' })
        localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
      }
    },
    // 文件上传
    async handleRequest(param) {
      let formData = new FormData()
      formData.append('file', param.file)
      let res = await this.$axios.post('/api/Common/upload', formData)
      if (res.code === 1) {
        let row = {
          uid: param.file.uid,
          name: param.file.name,
          url: res.data.fullurl,
        }
        this.fileList.push(row)
      }
    },
    beforeRemove(file) {
      let flag = false
      this.$confirm('您确定要移除此文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          flag = true
          for (let i = 0; i < this.fileList.length; i++) {
            if (this.fileList[i].uid === file.uid) {
              this.fileList.splice(i, 1)
              return
            }
          }
        })
        .catch(() => {})
      return flag
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 400px;
  margin: 80px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
  .el-icon-question {
    color: #2c3e50;
    cursor: pointer;
  }
}
.upload {
  margin-top: 20px;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
</style>
